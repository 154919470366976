import { actionTypes } from './constants'

import { action } from './index'
import { Calendar } from '../reducers/calendar'
import moment from 'moment'

export interface ApiCalendar {
  GameCode: string
  Season: string
  CompetitionCode: string
  ChampionshipCode: string
  PhaseCode: string
  MatchDay: number
  DateTime: string
  VenueCode: string
  Venue: string
  HomeClubCode: string
  HomeClub: string
  HomeClubShortCode: string
  AwayClubCode: string
  AwayClub: string
  AwayClubShortCode: string
  HomeResult: string
  AwayResult: string
  Quarter: string
  PlayTime: string
  GameStatus: number
  GamePostponed: number
  GameVictoryByForfeit: number
  GameSuspended: number
  GameAbandoned: number
  OfficialData: number
}

export const fetchCalendar = () => {
  return action(actionTypes.fetchCalendarRequest)
}

export const fetchCalendarSuccess = (calendar: ApiCalendar[]) => {
  const formattedCalendar: Calendar = calendar.reduce((accumulator, game) => {
    const matchDay = accumulator.hasOwnProperty(game.MatchDay)
      ? accumulator[game.MatchDay]
      : []
    const games = [
      ...matchDay,
      {
        awayTeam: game.AwayClub,
        homeTeam: game.HomeClub,
        awayTeamId: game.AwayClubCode,
        homeTeamId: game.HomeClubCode,
        awayScore: game.AwayResult,
        homeScore: game.HomeResult,
        date: game.DateTime,
        time: game.PlayTime,
        tv: '',
        stadium: game.Venue,
      },
    ]

    return {
      ...accumulator,
      [game.MatchDay]: games.sort((a, b) =>
        moment(a.date).diff(moment(b.date))
      ),
    }
  }, {} as Calendar)

  return action(actionTypes.fetchCalendarSuccess, {
    calendar: formattedCalendar,
  })
}

export const fetchCalendarError = () => {
  return action(actionTypes.fetchCalendarError)
}

export type CalendarActionTypes = ReturnType<
  typeof fetchCalendar | typeof fetchCalendarSuccess | typeof fetchCalendarError
>
