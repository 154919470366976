export const newsCategories = {
  academy: 57,
  academyCup: 76,
  news: 3,
  youth: 5,
  sponsor: 7,
  prNews: 48,
  weCare: 77,
} as const

export const actionTypes = {
  fetchArticlesRequest: 'FETCH_ARTICLES_REQUEST',
  fetchArticlesSuccess: 'FETCH_ARTICLES_SUCCESS',
  fetchArticlesError: 'FETCH_ARTICLES_ERROR',
  fetchArticlesUpdatesRequest: 'FETCH_ARTICLES_UPDATES_REQUEST',
  fetchArticlesUpdatesSuccess: 'FETCH_ARTICLES_UPDATES_SUCCESS',
  fetchArticlesUpdatesError: 'FETCH_ARTICLES_UPDATES_ERROR',

  fetchCalendarRequest: 'FETCH_CALENDAR_REQUEST',
  fetchCalendarSuccess: 'FETCH_CALENDAR_SUCCESS',
  fetchCalendarError: 'FETCH_CALENDAR_ERROR',

  fetchLeaderBoardRequest: 'FETCH_LEADER_BOARD_REQUEST',
  fetchLeaderBoardSuccess: 'FETCH_LEADER_BOARD_SUCCESS',
  fetchLeaderBoardError: 'FETCH_LEADER_BOARD_ERROR',
} as const
