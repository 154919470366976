import { fork } from 'redux-saga/effects'

import { articlesSaga } from './articles'
import { calendarSaga } from './calendar'
import { leaderBoardSaga } from './leaderboard'

export function* rootSaga() {
  yield fork(articlesSaga)
  yield fork(calendarSaga)
  yield fork(leaderBoardSaga)
}
