import { actionTypes } from './constants'
import { action } from './index'

export const fetchLeaderBoard = () => {
  return action(actionTypes.fetchLeaderBoardRequest)
}

export interface ApiLeaderBoardResponse {
  FinalRank: number
  ClubCode: string
  ClubName: string
  RankPoints: number
  MadePoints: number
  SufferedPoints: number
  GameWon: number
  GameLost: number
}

export const fetchLeaderBoardSuccess = (
  leaderBoard: ApiLeaderBoardResponse[]
) => {
  return action(actionTypes.fetchLeaderBoardSuccess, {
    leaderBoard: leaderBoard.map((team) => ({
      standing: team.FinalRank,
      teamId: team.ClubCode,
      teamName: team.ClubName,
      points: team.RankPoints,
      win: team.GameWon,
      loss: team.GameLost,
      pointsMade: team.MadePoints,
      pointsReceive: team.SufferedPoints,
    })),
  })
}

export const fetchLeaderBoardError = () => {
  return action(actionTypes.fetchLeaderBoardError, {
    leaderBoard: [],
  })
}

export type LeaderBoardActions = ReturnType<
  | typeof fetchLeaderBoard
  | typeof fetchLeaderBoardSuccess
  | typeof fetchLeaderBoardError
>
