import { CalendarActionTypes } from '../actions/calendar'
import { actionTypes } from '../actions/constants'

export interface CalendarGame {
  awayTeam: string
  homeTeam: string
  awayTeamId: string
  homeTeamId: string
  awayScore: string
  homeScore: string
  date: string
  time: string
  tv: string
  stadium: string
}

export interface Calendar {
  [key: number]: CalendarGame[]
}

interface CalendarState {
  fetching: boolean
  data: Calendar
  error: boolean
}

const CALENDAR_INITIAL_STATE: CalendarState = {
  fetching: false,
  data: {},
  error: false,
}

export const calendar = (
  state = CALENDAR_INITIAL_STATE,
  action: CalendarActionTypes
) => {
  switch (action.type) {
    case actionTypes.fetchCalendarRequest:
      return {
        ...state,
        fetching: true,
      }
    case actionTypes.fetchCalendarSuccess:
      return {
        ...state,
        fetching: false,
        error: false,
        data: action.payload.calendar,
      }
    case actionTypes.fetchCalendarError:
      return {
        ...state,
        fetching: false,
        error: true,
      }
    default:
      return state
  }
}
