import { actionTypes } from '../actions/constants'
import { LeaderBoardActions } from '../actions/leaderboard'

export interface LeaderBoardTeam {
  standing: string
  teamId: string
  teamName: string
  points: string
  win: string
  loss: string
  pointsMade: string
  pointsReceive: string
}

interface LeaderBoardState {
  readonly fetching: boolean
  readonly error: boolean
  readonly data: LeaderBoardTeam[]
}

const LEADER_BOARD_INITIAL_STATE: LeaderBoardState = {
  fetching: false,
  error: false,
  data: [],
}

export const leaderBoard = (
  state = LEADER_BOARD_INITIAL_STATE,
  action: LeaderBoardActions
) => {
  switch (action.type) {
    case actionTypes.fetchLeaderBoardRequest:
      return {
        ...state,
        fetching: true,
      }
    case actionTypes.fetchLeaderBoardSuccess:
      return {
        ...state,
        data: action.payload.leaderBoard,
        fetching: false,
        error: false,
      }
    case actionTypes.fetchLeaderBoardError:
      return {
        ...state,
        data: action.payload.leaderBoard,
        fetching: false,
        error: true,
      }
    default:
      return state
  }
}
