import { combineReducers } from 'redux'

import { articles } from './articles'
import { calendar } from './calendar'
import { leaderBoard } from './leaderboard'

export const rootReducer = combineReducers({
  articles,
  calendar,
  leaderBoard,
})

export type AppState = ReturnType<typeof rootReducer>
