import { ApiArticle } from '../actions/articles'
import { Article } from '../reducers/articles'

export const transformApiArticles = ({
  id,
  title,
  content,
  date,
  featuredImage,
  slug,
  sticky,
}: ApiArticle): Article => ({
  id,
  title: title.rendered,
  content: content.rendered,
  date,
  featuredImage,
  slug,
  sticky,
})
