import { actionTypes, newsCategories } from './constants'
import { action } from './index'

export interface ApiArticle {
  id: number
  title: {
    rendered: string
  }
  content: {
    rendered: string
  }
  date: string
  categories: number[]
  featured_media: number
  sticky: boolean
  slug: string
  featuredImage: {
    thumbnail: string
    full: string
  }
}

export interface ArticlesFilters {
  after?: string
  before?: string
  per_page?: number
  sticky?: boolean
}

export type NewsCategory = keyof typeof newsCategories

type FetchArticlesType =
  | typeof actionTypes.fetchArticlesRequest
  | typeof actionTypes.fetchArticlesUpdatesRequest

const makeFetchArticles = (actionType: FetchArticlesType) => {
  return (category: NewsCategory, filters: ArticlesFilters = {}) => {
    return action(actionType, {
      category,
      per_page: 12,
      ...filters,
    })
  }
}

export type FetchArticlesAction = ReturnType<typeof makeFetchArticles>

export const fetchArticles = makeFetchArticles(actionTypes.fetchArticlesRequest)
export const fetchArticlesUpdates = makeFetchArticles(
  actionTypes.fetchArticlesUpdatesRequest
)

type FetchArticlesSuccessType =
  | typeof actionTypes.fetchArticlesSuccess
  | typeof actionTypes.fetchArticlesUpdatesSuccess

const makeFetchArticlesSuccess = (actionType: FetchArticlesSuccessType) => {
  return (category: NewsCategory, articles: ApiArticle[]) => {
    return action(actionType, {
      category,
      articles,
    })
  }
}

export type FetchArticlesSuccessAction = ReturnType<
  typeof makeFetchArticlesSuccess
>

export const fetchArticlesSuccess = makeFetchArticlesSuccess(
  actionTypes.fetchArticlesSuccess
)
export const fetchArticlesUpdatesSuccess = makeFetchArticlesSuccess(
  actionTypes.fetchArticlesUpdatesSuccess
)

type FetchArticlesErrorType =
  | typeof actionTypes.fetchArticlesError
  | typeof actionTypes.fetchArticlesUpdatesError

const makeFetchArticlesError = (actionType: FetchArticlesErrorType) => {
  return (category: NewsCategory) => {
    return action(actionType, { category })
  }
}

export type FetchArticlesErrorAction = ReturnType<typeof makeFetchArticlesError>

export const fetchArticlesError = makeFetchArticlesError(
  actionTypes.fetchArticlesError
)
export const fetchArticlesUpdatesError = makeFetchArticlesError(
  actionTypes.fetchArticlesUpdatesError
)

export type ArticlesActions = ReturnType<
  FetchArticlesAction | FetchArticlesErrorAction | FetchArticlesSuccessAction
>
