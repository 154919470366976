import 'core-js/es/object/entries'
import { combineReducers } from 'redux'
import { persistReducer, createMigrate } from 'redux-persist'
import storage from 'localforage'

import { actionTypes, newsCategories } from '../actions/constants'
import { ArticlesActions } from '../actions/articles'
import { transformApiArticles } from '../utils/articles'

export interface Article {
  id: number
  title: string
  content: string
  date: string
  slug: string
  featuredImage?: {
    thumbnail: string
    full: string
  }
  sticky: boolean
}

export interface ArticlesState {
  readonly fetching: boolean
  readonly error: boolean
  readonly data: Article[]
  readonly category: number
  readonly hasMoreContent: boolean
}

const migrations = {
  '2': (state: ArticlesState) => ({
    ...state,
    fetching: false,
    error: false,
    data: [],
    hasMoreContent: true,
  }),
  '3': (state: ArticlesState) => ({
    ...state,
    fetching: false,
    error: false,
    data: [],
    hasMoreContent: true,
  }),
  '4': (state: ArticlesState) => ({
    ...state,
    fetching: false,
    error: false,
    data: [],
    hasMoreContent: true,
  }),
  '5': (state: ArticlesState) => ({
    ...state,
    fetching: false,
    error: false,
    data: [],
    hasMoreContent: true,
  }),
  '6': (state: ArticlesState) => ({
    ...state,
    fetching: false,
    error: false,
    data: [],
    hasMoreContent: true,
  }),
}

const makeArticlesReducer = (categoryId: number) => {
  const INITIAL_STATE: ArticlesState = {
    fetching: false,
    error: false,
    data: [],
    category: categoryId,
    hasMoreContent: true,
  }

  const reducer = (state = INITIAL_STATE, action: ArticlesActions) => {
    const category = action.payload?.category

    if (newsCategories[category] !== categoryId) {
      return state
    }

    switch (action.type) {
      case actionTypes.fetchArticlesRequest:
      case actionTypes.fetchArticlesUpdatesRequest:
        return {
          ...state,
          fetching: true,
        }
      case actionTypes.fetchArticlesSuccess:
        return {
          ...state,
          fetching: false,
          error: false,
          data: [
            ...state.data,
            ...action.payload.articles.map(transformApiArticles),
          ],
          hasMoreContent: action.payload.articles.length > 0,
        }
      case actionTypes.fetchArticlesUpdatesSuccess:
        return {
          ...state,
          fetching: false,
          error: false,
          data: [
            ...action.payload.articles.map(transformApiArticles),
            ...state.data,
          ],
        }
      case actionTypes.fetchArticlesError:
        return {
          ...state,
          fetching: false,
          error: true,
        }
      //case actionTypes.fetchArticlesUpdatesError:
      default:
        return state
    }
  }

  const articlesPersistConfig = {
    key: `articles_${categoryId}`,
    storage,
    blacklist: ['fetching', 'error'],
    version: 6,
    // @ts-ignore
    migrate: createMigrate(migrations),
  }

  return persistReducer(articlesPersistConfig, reducer)
}

export const articles = combineReducers({
  academy: makeArticlesReducer(newsCategories.academy),
  academyCup: makeArticlesReducer(newsCategories.academyCup),
  news: makeArticlesReducer(newsCategories.news),
  youth: makeArticlesReducer(newsCategories.youth),
  sponsor: makeArticlesReducer(newsCategories.sponsor),
  prNews: makeArticlesReducer(newsCategories.prNews),
  weCare: makeArticlesReducer(newsCategories.weCare),
})
