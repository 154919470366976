const jsonMiddleware = (res: Response) => {
  if (res.ok) {
    return res.json()
  }

  throw new Error(res.statusText)
}

const headers: HeadersInit = {
  'Content-Type': 'application/json',
}

class Api {
  private readonly apiUrl: string
  private readonly resMiddleware: (res: Response) => Promise<any>
  private readonly headers: HeadersInit

  constructor(
    apiUrl: string,
    middleware: (res: Response) => Promise<any>,
    headers = {}
  ) {
    this.apiUrl = apiUrl
    this.resMiddleware = middleware
    this.headers = headers
  }

  private _getUrl(url: string) {
    return this.apiUrl + url
  }

  get<T = any>(url: string): Promise<T> {
    return fetch(this._getUrl(url), {
      headers: {
        ...headers,
        ...this.headers,
      },
    }).then(this.resMiddleware)
  }
}

export const api = new Api(process.env.REACT_APP_API_URL, jsonMiddleware)
export const WooCommerce = new Api(
  `${process.env.REACT_APP_STORE_URL}/wp-json/wc/v3`,
  jsonMiddleware,
  { Accept: 'application/json' }
)
export const lbApi = new Api(
  `${process.env.REACT_APP_API_URL}/legabasket`,
  jsonMiddleware
)
