import React from 'react'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, Middleware } from 'redux'
import { logger } from 'redux-logger'
import { persistStore, persistReducer } from 'redux-persist'
import 'regenerator-runtime/runtime'
import createSagaMiddleware from 'redux-saga'
import storage from 'localforage'

import { App } from './App'

import { rootReducer } from '../../reducers'
import { rootSaga } from '../../sagas'

const sagaMiddleware = createSagaMiddleware()
const middlewares: Middleware[] = [sagaMiddleware]

if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger)
}

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['articles'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
const store = createStore(persistedReducer, applyMiddleware(...middlewares))
export const persistor = persistStore(store)

sagaMiddleware.run(rootSaga)

interface Props {
  element: React.ReactNode
}

export default ({ element }: Props) => (
  <Provider store={store}>
    <App>{element}</App>
  </Provider>
)
