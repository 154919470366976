import { takeLatest, call, put } from 'redux-saga/effects'

import { actionTypes } from '../actions/constants'
import {
  ApiLeaderBoardResponse,
  fetchLeaderBoardSuccess,
  fetchLeaderBoardError,
} from '../actions/leaderboard'

import { lbApi } from '../utils/api'

function* fetchLeaderBoard() {
  try {
    const data: ApiLeaderBoardResponse[] = yield call(
      [lbApi, lbApi.get],
      '/standings'
    )
    const payload = data.length === 0 ? [] : data

    yield put(fetchLeaderBoardSuccess(payload))
  } catch {
    yield put(fetchLeaderBoardError())
  }
}

export function* leaderBoardSaga() {
  yield takeLatest(actionTypes.fetchLeaderBoardRequest, fetchLeaderBoard)
}
