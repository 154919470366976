import { takeLatest, put, call } from 'redux-saga/effects'

import { actionTypes } from '../actions/constants'
import { fetchCalendarSuccess, fetchCalendarError } from '../actions/calendar'
import { lbApi } from '../utils/api'

function* fetchCalendar() {
  try {
    // @ts-ignore
    const data = yield call([lbApi, lbApi.get], '/games')

    yield put(fetchCalendarSuccess(data))
  } catch (e) {
    console.log(e)
    yield put(fetchCalendarError())
  }
}

export function* calendarSaga() {
  yield takeLatest(actionTypes.fetchCalendarRequest, fetchCalendar)
}
