import { createSelector } from 'reselect'

import { AppState } from '../reducers'
import { ArticlesState, Article } from '../reducers/articles'

export const getArticles = (state: AppState) => state.articles

export type ArticleTypes = keyof AppState['articles']

export const getCategoryArticles =
  (category: ArticleTypes) => (state: AppState) => {
    const articles = getArticles(state)

    return articles[category] as ArticlesState
  }

export const getAllArticles = createSelector([getArticles], (articles) => {
  return Object.values<ArticlesState>(articles).reduce<Article[]>(
    (accumulator, { data }) => {
      return [...accumulator, ...data]
    },
    []
  )
})

export const getFeaturedArticles =
  (category: ArticleTypes) => (state: AppState) => {
    const articles = getCategoryArticles(category)(state)

    return {
      ...articles,
      data: articles.data.filter((article) => article.sticky),
    }
  }

export type ArticleStateSelectors = typeof getCategoryArticles
