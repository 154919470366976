import { takeLeading, all, call, put, select } from 'redux-saga/effects'
import qs from 'qs'

import { actionTypes } from '../actions/constants'
import { api } from '../utils/api'
import { ArticlesState } from '../reducers/articles'
import {
  ApiArticle,
  ArticlesFilters,
  FetchArticlesAction,
  fetchArticlesSuccess,
  fetchArticlesUpdatesSuccess,
  fetchArticlesUpdatesError,
  FetchArticlesSuccessAction,
  fetchArticlesError,
  FetchArticlesErrorAction,
} from '../actions/articles'
import { getCategoryArticles } from '../selectors/articles'

type ArticlesFetchRequestAction = ReturnType<FetchArticlesAction>

const makeFetchArticlesSaga = (
  successAction: FetchArticlesSuccessAction,
  errorAction?: FetchArticlesErrorAction,
  getParamsFromState?: (state: ArticlesState) => ArticlesFilters
) => {
  return function* (action: ArticlesFetchRequestAction) {
    const { category, ...payload } = action.payload

    try {
      const articles: ArticlesState = yield select(
        getCategoryArticles(category)
      )
      const queryParams = qs.stringify({
        ...payload,
        ...(getParamsFromState && getParamsFromState(articles)),
        categories: articles.category,
      })
      const data: ApiArticle[] = yield call(
        [api, api.get],
        '/posts?' + queryParams
      )

      yield put(successAction(category, data))
    } catch {
      if (errorAction) {
        yield put(errorAction(category))
      }
    }
  }
}

const fetchArticlesSaga = makeFetchArticlesSaga(
  fetchArticlesSuccess,
  fetchArticlesError,
  (state) => {
    const before = state.data[state.data.length - 1]?.date

    return { before }
  }
)

const fetchArticlesUpdatesSaga = makeFetchArticlesSaga(
  fetchArticlesUpdatesSuccess,
  fetchArticlesUpdatesError,
  (state) => {
    const latestArticleDate = state.data[0]?.date

    return { after: latestArticleDate }
  }
)

export function* articlesSaga() {
  yield all([
    takeLeading(actionTypes.fetchArticlesRequest, fetchArticlesSaga),
    takeLeading(
      actionTypes.fetchArticlesUpdatesRequest,
      fetchArticlesUpdatesSaga
    ),
  ])
}
